import {
    loadProducts,
    loadFirstProduct,
    loadProduct,
    loadBusiness,
    loadCategories,
    loadPromos,
    lockProduct,
    updateImages
} from "./products";

export default {
    getFirst: async (uid) => {
        return await loadFirstProduct(uid);
    },
    getProducts: async (uid) => {
        return await loadProducts(uid);
    },

    getPromos: async (uid) => {
        return await loadPromos(uid);
    },

    getProduct: async (id) => {
        return await loadProduct(id);
    },

    lockProduct: async (id, quantityToDecrease) => {
        return await lockProduct(id, quantityToDecrease);
    },

    getCategories: async (id) => {
        return await loadCategories(id);
    },

    getBusiness: async (uid) => {
        return await loadBusiness(uid);
    },

    updateImages: async (id) => {
        return await updateImages(id);
    },
};
